import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { createBrowserHistory } from 'history';
import { create } from 'jss';
import rtl from 'jss-rtl';
import MomentUtils from '@date-io/moment';
import { useDispatch, useSelector } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import {
  createStyles,
  createTheme,
  jssPreset,
  makeStyles,
  StylesProvider,
  ThemeProvider
} from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import Auth from './Auth';
import { getSettings } from 'src/services/settingService';
import { settingSelector } from 'src/slices/settings';
import ScrollReset from 'src/components/ScrollReset';
import useSettings from 'src/hooks/useSettings';
import { createThemes } from 'src/theme';
import GlobalStyles from 'src/components/GlobalStyles';
// Authentication view
import Login from 'src/views/auth/LoginView';
import PasswordForgot from 'src/views/auth/PasswordForgot';
import PasswordForgotOtpView from './views/auth/PasswordForgot/CodeView';
import ResetPasswordView from './views/auth/PasswordForgot/ResetPassword';
import RegisterView from 'src/views/auth/RegisterView';
import Registration from 'src/views/auth/Registration';
import RegistrationInfo from 'src/views/auth/RegistrationInfo';
import RegisterInfoConfirm from 'src/views/auth/RegisterInfoConfirm';
import CreditCardInputInfo from './views/auth/Payment';
import PaymentComplete from './views/auth/PaymentFinish';
import MyPageView from './views/mypage';
import MyPagePaymentATM from './views/mypage/PaymentATM';
import MyPagePaymentATMFinish from './views/mypage/PaymentATMFinish';
import MyPagePaymentStore from './views/mypage/PaymentStore';
import MyPagePaymentStoreFinish from './views/mypage/PaymentStoreFinish';
import MyPagePaymentBanking from './views/mypage/PaymentBanking';
import MyPagePaymentBankingFinish from './views/mypage/PaymentBankingFinish';
import EditProfileView from './views/mypage/EditProfile';
import DeleteAccount from './views/mypage/DeleteAccount';
import ChangeEmail from './views/mypage/ChangeEmail';
import CodeConfirmChangeEmail from './views/mypage/CodeConfirm';
import UpdatePassword from './views/mypage/UpdatePassword';
import PaymentType from './views/mypage/PaymentType';
import ChooseCredit from './views/mypage/ChooseCredit';
import CreditCardInput from './views/mypage/CreditCardInput';
import PaymentFinish from './views/mypage/PaymentFinish';
import PaymentATM from './views/auth/PaymentATM';
import PaymentATMFinish from './views/auth/PaymentATMFinish';
import PaymentStore from './views/auth/PaymentStore';
import PaymentStoreFinish from './views/auth/PaymentStoreFinish';
import PaymentBanking from './views/auth/PaymentBanking';
import PaymentBankingFinish from './views/auth/PaymentBankingFinish';
import MemberBenefits from './views/auth/MemberBenefits';
import AboutPayment from './views/static/AboutPayment';
import UpdateProfile from './views/auth/UpdateProfile';

// Home view
import HomeView from 'src/views/home';
import NoticeView from 'src/views/notice';
import ProfileView from 'src/views/profile';
import DiscographyView from 'src/views/discography';
import Photo from 'src/views/photo';
import PhotoDetails from 'src/views/photo/PhotoDetails';
import YoutubeView from 'src/views/youtube';
import YoutubeDetail from './views/youtube/YoutubeDetail';
import VideoView from 'src/views/video';
import VideoDetail from 'src/views/video/VideoDetail';
import MagazineView from './views/magazine';
import MagazineDetail from './views/magazine/MagazineDetail';
import StaffBlogView from './views/staff-blog';
import BlogDetail from './views/staff-blog/BlogDetail';
import StaffConfirm from './views/staff-blog/StaffConfirm';
import TalentBlogView from './views/talent-blog';
import TalentBlogDetail from './views/talent-blog/BlogDetail';
import TalentConfirm from './views/talent-blog/TalentConfirm';
import DownloadView from './views/download';
import { EVENTS, ROUTES, ROUTES_AUTHEN } from './constants';
import Layout from './layouts';
import NoticeDetail from './views/notice/NoticeDetail';
import Terms from './views/static/Terms';
import Site from './views/static/Site';
import Privacy from './views/static/Privacy';
import Law from './views/static/Law';
import FAQView from './views/static/FAQ';
import InquiryView from './views/inquiry';
import InquiryConfirmView from './views/inquiry/confirm';
import InquiryFinishView from './views/inquiry/finish';
import LoveLetterView from './views/loveletter';
import WriteLetterView from './views/loveletter/letter';
import SendLetterPreview from './views/loveletter/sendLetter';
import SendLetterSuccess from './views/loveletter/success';
import ScheduleView from './views/schedule';
import Community from './views/community';
import CommunityBulletinBoard from './views/community/Board';
import CommunityOnBoard from './views/community/OnBoard';
import CommunityPostDetail from './views/community/Post';
import PostCommunity from './views/community/PostCommunity';
import CommunityPostConfirm from './views/community/PostConfirm';
import Error404View from './views/pages/Error404View';
import { authSelector } from './slices/auth';
const history = createBrowserHistory();

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

const THEME = createTheme({
  typography: {
    fontFamily: 'futura-pt, source-han-sans-japanese, sans-serif',
    fontSize: 16,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 'bold'
  }
});

const useStyles = makeStyles(() =>
  createStyles({
    '@global': {
      '*': {
        boxSizing: 'border-box',
        margin: 0,
        padding: 0
      },
      html: {
        '-webkit-font-smoothing': 'antialiased',
        '-moz-osx-font-smoothing': 'grayscale',
        height: '100%',
        width: '100%'
      },
      body: {
        height: '100%',
        width: '100%'
      },
      '#root': {
        height: '100%',
        width: '100%'
      }
    }
  })
);

function App() {
  useStyles();
  const dispatch = useDispatch();
  const setting = useSelector(settingSelector);
  const userProfile = useSelector(authSelector);

  const checkTrackingId = () => {
    const domain = window.location.host;
    switch (domain) {
      case process.env.REACT_APP_TALENT_SONG_JOON_KI_DOMAIN:
        return 'G-MKVSVMJ0YK';
      case process.env.REACT_APP_TALENT_KIM_YOUNG_DEA_DOMAIN:
        return 'G-3YFB3BHL8P';
      case process.env.REACT_APP_TALENT_LEE_JONG_SUK_DOMAIN:
        return 'G-J2CRGFFQJJ';
      case process.env.REACT_APP_TALENT_ZO_IN_SUNG_DOMAIN:
        return 'G-D19PLX2BME';
      case process.env.REACT_APP_TALENT_KIM_JI_WON_DOMAIN:
        return 'G-MT2SKZLWWT';
      case process.env.REACT_APP_TALENT_PARK_MIN_YOUNG_DOMAIN:
        return 'G-RZTVQLFS1B';
      case process.env.REACT_APP_TALENT_YOO_SEUNG_HO_DOMAIN:
        return 'G-FT48X2GRQW';
      case process.env.REACT_APP_TALENT_YOOK_SUNGJAE_DOMAIN:
        return 'G-7H83Y73JKT';
      default:
        return false;
    }
  };

  if (checkTrackingId()) {
    ReactGA.initialize(checkTrackingId());
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname,
      title: window.location.pathname
    });
  }

  useEffect(() => {
    const handleContextmenu = (e) => {
      e.preventDefault();
    };
    document.addEventListener('contextmenu', handleContextmenu);
    return function cleanup() {
      document.removeEventListener('contextmenu', handleContextmenu);
    };
  }, []);

  useEffect(() => {
    dispatch(getSettings());
  }, []);

  const { settings } = useSettings();

  return (
    <ThemeProvider theme={THEME}>
      <StylesProvider jss={jss}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <SnackbarProvider maxSnack={1}>
            <Router history={history}>
              <Auth>
                <GlobalStyles />
                <Layout profile={userProfile}>
                  <Routes>
                    <Route
                      index
                      path={ROUTES.HOMEVIEW}
                      element={<HomeView profile={userProfile} />}
                    />
                    <Route path={ROUTES_AUTHEN.LOGIN} element={<Login />} />
                    <Route
                      path={ROUTES_AUTHEN.FORGOT_PASSWORD}
                      element={<PasswordForgot />}
                    />
                    <Route
                      path={ROUTES_AUTHEN.FORGOT_PASSWORD_OTP}
                      element={<PasswordForgotOtpView />}
                    />
                    <Route
                      path={ROUTES_AUTHEN.RESET_PASSWORD}
                      element={<ResetPasswordView />}
                    />
                    <Route
                      path={ROUTES_AUTHEN.REGISTER}
                      element={<RegisterView />}
                    />
                    <Route
                      path={ROUTES_AUTHEN.REGISTRATION}
                      element={<Registration />}
                    />
                    <Route
                      path={ROUTES_AUTHEN.REGISTRATION_INFO}
                      element={<RegistrationInfo />}
                    />
                    <Route
                      path={ROUTES_AUTHEN.REGISTER_INFO_CONFIRM}
                      element={<RegisterInfoConfirm />}
                    />
                    <Route
                      path={ROUTES_AUTHEN.UPDATE_PROFILE}
                      element={<UpdateProfile setting={setting?.settings} />}
                    />
                    <Route
                      path={ROUTES_AUTHEN.PAYMENT_CREDIT_INFO}
                      element={<CreditCardInputInfo />}
                    />
                    <Route
                      path={ROUTES_AUTHEN.PAYMENT_COMPLETE}
                      element={<PaymentComplete />}
                    />
                    <Route
                      path={ROUTES_AUTHEN.PAYMENT_ATM}
                      element={<PaymentATM />}
                    />
                    <Route
                      path={ROUTES_AUTHEN.PAYMENT_ATM_FINISH}
                      element={<PaymentATMFinish />}
                    />
                    <Route
                      path={ROUTES_AUTHEN.PAYMENT_STORE}
                      element={<PaymentStore />}
                    />
                    <Route
                      path={ROUTES_AUTHEN.PAYMENT_STORE_FINISH}
                      element={<PaymentStoreFinish />}
                    />
                    <Route
                      path={ROUTES_AUTHEN.PAYMENT_BANKING}
                      element={<PaymentBanking />}
                    />
                    <Route
                      path={ROUTES_AUTHEN.PAYMENT_BANKING_FINISH}
                      element={<PaymentBankingFinish />}
                    />
                    <Route
                      path={ROUTES.NOTICE}
                      element={<NoticeView setting={setting?.settings} />}
                    />
                    <Route
                      path={`${ROUTES.NOTICE}/:id`}
                      element={<NoticeDetail setting={setting?.settings} />}
                    />
                    <Route
                      path={ROUTES.PROFILE}
                      element={<ProfileView setting={setting?.settings} />}
                    />
                    <Route
                      path={ROUTES.DISCOGRAPHY}
                      element={<DiscographyView setting={setting?.settings} />}
                    />
                    <Route
                      path={ROUTES.PHOTO}
                      element={<Photo setting={setting?.settings} />}
                    />
                    <Route
                      path={`${ROUTES.PHOTO}/:id`}
                      element={<PhotoDetails />}
                    />
                    <Route
                      path={ROUTES.DISCOGRAPHY}
                      element={<DiscographyView />}
                    />
                    <Route path={ROUTES.YOUTUBE} element={<YoutubeView />} />
                    <Route
                      path={`${ROUTES.YOUTUBE}/:id`}
                      element={<YoutubeDetail setting={setting?.settings} />}
                    />
                    <Route
                      path={ROUTES.VIDEO}
                      element={<VideoView setting={setting?.settings} />}
                    />
                    <Route
                      path={`${ROUTES.VIDEO}/:id`}
                      element={<VideoDetail setting={setting?.settings} />}
                    />

                    <Route path={ROUTES.MAGAZINE} element={<MagazineView />} />
                    <Route
                      path={`${ROUTES.MAGAZINE}/:id`}
                      element={<MagazineDetail />}
                    />
                    <Route
                      path={ROUTES.STAFF_BLOG}
                      element={<StaffBlogView setting={setting?.settings} />}
                    />
                    <Route
                      path={`${ROUTES.STAFF_BLOG}/:id`}
                      element={<BlogDetail setting={setting?.settings} />}
                    />
                    <Route
                      path={ROUTES.STAFF_BLOG_CONFIRM}
                      element={<StaffConfirm />}
                    />

                    <Route
                      path={ROUTES.TALENT_BLOG}
                      element={<TalentBlogView setting={setting?.settings} />}
                    />
                    <Route
                      path={`${ROUTES.TALENT_BLOG}/:id`}
                      element={<TalentBlogDetail setting={setting?.settings} />}
                    />
                    <Route
                      path={ROUTES.TALENT_BLOG_CONFIRM}
                      element={<TalentConfirm />}
                    />
                    <Route path={ROUTES.SCHEDULE} element={<ScheduleView />} />

                    <Route path={ROUTES.DOWNLOAD} element={<DownloadView />} />
                    <Route
                      path={ROUTES_AUTHEN.MEMBER_BENEFITS}
                      element={<MemberBenefits />}
                    />
                    {/* My Page */}
                    <Route
                      path={ROUTES_AUTHEN.MY_PAGE}
                      element={<MyPageView setting={setting?.settings} />}
                    />
                    <Route
                      path={ROUTES_AUTHEN.EDIT_PROFILE}
                      element={<EditProfileView setting={setting?.settings} />}
                    />
                    <Route
                      path={ROUTES_AUTHEN.DELETE_ACCOUNT}
                      element={<DeleteAccount setting={setting?.settings} />}
                    />
                    <Route
                      path={ROUTES_AUTHEN.CHANGE_EMAIL}
                      element={<ChangeEmail />}
                    />
                    <Route
                      path={ROUTES_AUTHEN.CHANGE_EMAIL_OTP}
                      element={<CodeConfirmChangeEmail />}
                    />
                    <Route
                      path={ROUTES_AUTHEN.UPDATE_PASSWORD}
                      element={<UpdatePassword />}
                    />
                    <Route
                      path={ROUTES_AUTHEN.PAYMENT_TYPE}
                      element={<PaymentType />}
                    />
                    <Route
                      path={ROUTES_AUTHEN.CHOOSE_CREDIT}
                      element={<ChooseCredit />}
                    />
                    <Route
                      path={ROUTES_AUTHEN.CREDIT_INFO}
                      element={<CreditCardInput />}
                    />
                    <Route
                      path={ROUTES_AUTHEN.PAYMENT_FINISH}
                      element={<PaymentFinish />}
                    />
                    <Route
                      path={ROUTES_AUTHEN.MY_PAGE_PAYMENT_ATM}
                      element={<MyPagePaymentATM />}
                    />
                    <Route
                      path={ROUTES_AUTHEN.MY_PAGE_PAYMENT_ATM_FINISH}
                      element={<MyPagePaymentATMFinish />}
                    />
                    <Route
                      path={ROUTES_AUTHEN.MY_PAGE_PAYMENT_STORE}
                      element={<MyPagePaymentStore />}
                    />
                    <Route
                      path={ROUTES_AUTHEN.MY_PAGE_PAYMENT_STORE_FINISH}
                      element={<MyPagePaymentStoreFinish />}
                    />
                    <Route
                      path={ROUTES_AUTHEN.MY_PAGE_PAYMENT_BANKING}
                      element={<MyPagePaymentBanking />}
                    />
                    <Route
                      path={ROUTES_AUTHEN.MY_PAGE_PAYMENT_BANKING_FINISH}
                      element={<MyPagePaymentBankingFinish />}
                    />
                    <Route path={ROUTES.COMMUNITY} element={<Community />} />
                    <Route
                      path={ROUTES_AUTHEN.COMMUNITY_BOARD}
                      element={<CommunityBulletinBoard />}
                    />
                    <Route
                      path={`${ROUTES_AUTHEN.COMMUNITY_ON_BOARD}/:id`}
                      element={<CommunityOnBoard />}
                    />
                    <Route
                      path={`${ROUTES_AUTHEN.COMMUNITY_POST}/:id`}
                      element={
                        <CommunityPostDetail setting={setting?.settings} />
                      }
                    />
                    <Route
                      path={`${ROUTES_AUTHEN.POST_COMMUNITY}/:id`}
                      element={<PostCommunity />}
                    />
                    <Route
                      path={`${ROUTES_AUTHEN.COMMUNITY_POST_CONFIRM}/:id`}
                      element={<CommunityPostConfirm />}
                    />

                    {/* ----Static Page---- */}
                    <Route path={ROUTES.TERMS} element={<Terms />} />
                    <Route path={ROUTES.SITE} element={<Site />} />
                    <Route path={ROUTES.PRIVACY} element={<Privacy />} />
                    <Route path={ROUTES.LAW} element={<Law />} />
                    <Route
                      path={ROUTES.FAQ}
                      element={<FAQView setting={setting?.settings} />}
                    />
                    <Route
                      path={ROUTES.ABOUT_PAYMENT}
                      element={<AboutPayment />}
                    />
                    <Route path={ROUTES.INQUIRY} element={<InquiryView />} />
                    <Route
                      path={ROUTES.INQUIRY_CONFIRM}
                      element={<InquiryConfirmView />}
                    />
                    <Route
                      path={ROUTES.INQUIRY_FINISH}
                      element={<InquiryFinishView />}
                    />
                    {/* ----Static Page---- */}

                    {/* Love letter */}
                    <Route
                      path={ROUTES.LOVE_LETTER}
                      element={<LoveLetterView />}
                    />
                    <Route
                      path={ROUTES.LOVE_LETTER_WRITE}
                      element={<WriteLetterView />}
                    />
                    <Route
                      path={ROUTES.SEND_LETTER}
                      element={<SendLetterPreview />}
                    />
                    <Route
                      path={ROUTES.SEND_LETTER_SUCCESS}
                      element={<SendLetterSuccess />}
                    />
                    <Route
                      exact
                      path={EVENTS.HELLO_AGAIN}
                      render={() => {
                        window.location.href =
                          '/event/2023_helloagain/index.html';
                      }}
                    />
                    <Route path="*" element={<Error404View />} />
                  </Routes>
                </Layout>
                <ScrollReset />
              </Auth>
            </Router>
          </SnackbarProvider>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </ThemeProvider>
  );
}

export default App;
